.request {
	padding: 70px 0 80px;
	text-align: center;

	.default-title {
		text-transform: uppercase;
		font-size: 36px;
	}

	&--grey {
		background: #f5f4f1;

		.input {
			background: #ebeae8;
		}
	}
}

.request__text {
	color: #343542;
	font-size: 18px;
	line-height: 30px;
	font-weight: 300;
	margin: 20px 0 50px;
}

.request__form {
	width: 600px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.input {
		width: 49%;
		margin-bottom: 20px;

		&--wider {
			width: 100%;
		}
	}
}

.request__form-button {
	width: 100%;
	margin-top: 10px;
}