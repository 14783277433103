.btn {
	display: inline-block;
	text-decoration: none;
	border: none;
	padding: 0;
}

.btn-default {
	background-color: #b92c12;
	border-radius: 8px;
	color: #fff;
	font-size: 24px;
	font-weight: 300;
	padding: 23px 40px 25px;
}

.default-title {
	color: #343542;
	font-size: 30px;
	line-height: 40px;
	font-weight: 300;
	margin: 0;
	padding-bottom: 15px;
	position: relative;

	&--left::after,
	&--center::after {
		content: '';
		display: block;
		width: 170px;
		height: 2px;
		background: #bb3118;
		position: absolute;
		bottom: 0;
	}

	&--left::after {
		left: 0;
	}

	&--center::after {
		left: 50%;
		transform: translateX(-50%);
	}
}

.input {
	height: 60px;
	background-color: #f5f5f5;
	border-radius: 8px;
	border: none;
	padding: 0 20px;
	box-sizing: border-box;
}

.textarea {
	background-color: #f5f5f5;
	border-radius: 8px;
	border: none;
	padding: 10px 20px;
	box-sizing: border-box;
	resize: vertical;
}

.breadcrumbs {
	margin-bottom: 20px;
}

.breadcrumbs__list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: flex-start;
	font-size: 14px;
	line-height: 24px;
}

.breadcrumbs__list-item {
	&:not(:first-child)::before {
		content: '-';
		color: #2c2f39;
		display: inline-block;
		padding-left: 5px;
	}
}

.breadcrumbs__list-link {
	color: #de3000;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.pagination {
	margin-bottom: 20px;
}

.pagination__list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.pagination__list-item {
	border: 2px solid transparent;
	width: 34px;
	height: 34px;
	box-sizing: border-box;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&--active {
		border: 2px solid #da3200;
	}
}

.pagination__list-link {
	color: #2d2f38;
	font-size: 22px;
	font-weight: 600;
	text-decoration: none;
	line-height: 34px;

	&:hover {
		color: #da3200;
	}
}

h3 {
	color: #3f4556;
	font-size: 18px;
	line-height: 26px;
	font-weight: 700;
}

.footnote {
	padding: 15px 20px;
	margin: 40px 0 40px 70px;
	border-left: 5px solid #dc3100;
	color: #3f4556;
	font-size: 16px;
	line-height: 24px;
	font-style: italic;
}

a {
	color: #9c0032;
	text-decoration: none;
}