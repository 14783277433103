@import "../sprite";

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 42px 22px;
}

.header__contacts {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
}

.header__contacts-address {
	font-weight: 300;
	margin-right: 150px;
}

.header__contacts-phone {
	display: flex;
	align-items: center;
	font-weight: 600;
	text-transform: uppercase;

	&::before {
		content: '';
		@include sprite($icon-phone);
		display: inline-block;
		margin-right: 20px;
	}
}