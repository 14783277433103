@import "../sprite";

.top-block {
	background: #fff url('../img/top-block-bg.jpg') 0 0 no-repeat;
	background-size: cover;
	padding: 60px 0 90px;
	position: relative;
	border-bottom: 2px solid #b92c12;
}

.top-block__content {
	padding-left: 40px;
	color: #2d2f38;
}

.top-block__title {
	font-size: 60px;
	line-height: 72px;
	font-weight: 800;
	text-transform: uppercase;
	margin: 0;
}

.top-block__text {
	font-size: 18px;
	line-height: 30px;
	font-weight: 400;
	margin: 25px 0 40px;
}

.top-block__scroll {
	font-size: 0;
	@include sprite($icon-scroll);
	display: inline-block;
	position: absolute;
	left: 50%;
	bottom: 40px;
	transform: translateX(-50%);
}