@import "../sprite";

.navigation {
	background: #2d2f38;
}

.navigation__list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
}

.navigation__list-item {
	padding: 0 40px;
	position: relative;

	&:not(first-child)::before {
		content: '';
		@include sprite($icon-deliver);
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&--active .navigation__list-link,
	&:hover .navigation__list-link {
		border-bottom: 2px solid #dc3c00;
	}
}

.navigation__list-link {
	display: inline-block;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 58px;
	border-bottom: 2px solid transparent;
}