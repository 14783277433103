// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-address-name: 'icon-address';
$icon-address-x: 0px;
$icon-address-y: 120px;
$icon-address-offset-x: 0px;
$icon-address-offset-y: -120px;
$icon-address-width: 40px;
$icon-address-height: 40px;
$icon-address-total-width: 140px;
$icon-address-total-height: 985px;
$icon-address-image: '../img/sprite.png';
$icon-address: (0px, 120px, 0px, -120px, 40px, 40px, 140px, 985px, '../img/sprite.png', 'icon-address', );
$icon-benefit-1-name: 'icon-benefit-1';
$icon-benefit-1-x: 0px;
$icon-benefit-1-y: 425px;
$icon-benefit-1-offset-x: 0px;
$icon-benefit-1-offset-y: -425px;
$icon-benefit-1-width: 140px;
$icon-benefit-1-height: 140px;
$icon-benefit-1-total-width: 140px;
$icon-benefit-1-total-height: 985px;
$icon-benefit-1-image: '../img/sprite.png';
$icon-benefit-1: (0px, 425px, 0px, -425px, 140px, 140px, 140px, 985px, '../img/sprite.png', 'icon-benefit-1', );
$icon-benefit-2-name: 'icon-benefit-2';
$icon-benefit-2-x: 0px;
$icon-benefit-2-y: 705px;
$icon-benefit-2-offset-x: 0px;
$icon-benefit-2-offset-y: -705px;
$icon-benefit-2-width: 140px;
$icon-benefit-2-height: 140px;
$icon-benefit-2-total-width: 140px;
$icon-benefit-2-total-height: 985px;
$icon-benefit-2-image: '../img/sprite.png';
$icon-benefit-2: (0px, 705px, 0px, -705px, 140px, 140px, 140px, 985px, '../img/sprite.png', 'icon-benefit-2', );
$icon-benefit-3-name: 'icon-benefit-3';
$icon-benefit-3-x: 0px;
$icon-benefit-3-y: 845px;
$icon-benefit-3-offset-x: 0px;
$icon-benefit-3-offset-y: -845px;
$icon-benefit-3-width: 140px;
$icon-benefit-3-height: 140px;
$icon-benefit-3-total-width: 140px;
$icon-benefit-3-total-height: 985px;
$icon-benefit-3-image: '../img/sprite.png';
$icon-benefit-3: (0px, 845px, 0px, -845px, 140px, 140px, 140px, 985px, '../img/sprite.png', 'icon-benefit-3', );
$icon-benefit-4-name: 'icon-benefit-4';
$icon-benefit-4-x: 0px;
$icon-benefit-4-y: 285px;
$icon-benefit-4-offset-x: 0px;
$icon-benefit-4-offset-y: -285px;
$icon-benefit-4-width: 140px;
$icon-benefit-4-height: 140px;
$icon-benefit-4-total-width: 140px;
$icon-benefit-4-total-height: 985px;
$icon-benefit-4-image: '../img/sprite.png';
$icon-benefit-4: (0px, 285px, 0px, -285px, 140px, 140px, 140px, 985px, '../img/sprite.png', 'icon-benefit-4', );
$icon-benefit-5-name: 'icon-benefit-5';
$icon-benefit-5-x: 0px;
$icon-benefit-5-y: 565px;
$icon-benefit-5-offset-x: 0px;
$icon-benefit-5-offset-y: -565px;
$icon-benefit-5-width: 140px;
$icon-benefit-5-height: 140px;
$icon-benefit-5-total-width: 140px;
$icon-benefit-5-total-height: 985px;
$icon-benefit-5-image: '../img/sprite.png';
$icon-benefit-5: (0px, 565px, 0px, -565px, 140px, 140px, 140px, 985px, '../img/sprite.png', 'icon-benefit-5', );
$icon-benefit-arrow-name: 'icon-benefit-arrow';
$icon-benefit-arrow-x: 0px;
$icon-benefit-arrow-y: 37px;
$icon-benefit-arrow-offset-x: 0px;
$icon-benefit-arrow-offset-y: -37px;
$icon-benefit-arrow-width: 12px;
$icon-benefit-arrow-height: 23px;
$icon-benefit-arrow-total-width: 140px;
$icon-benefit-arrow-total-height: 985px;
$icon-benefit-arrow-image: '../img/sprite.png';
$icon-benefit-arrow: (0px, 37px, 0px, -37px, 12px, 23px, 140px, 985px, '../img/sprite.png', 'icon-benefit-arrow', );
$icon-comment-name: 'icon-comment';
$icon-comment-x: 0px;
$icon-comment-y: 0px;
$icon-comment-offset-x: 0px;
$icon-comment-offset-y: 0px;
$icon-comment-width: 14px;
$icon-comment-height: 12px;
$icon-comment-total-width: 140px;
$icon-comment-total-height: 985px;
$icon-comment-image: '../img/sprite.png';
$icon-comment: (0px, 0px, 0px, 0px, 14px, 12px, 140px, 985px, '../img/sprite.png', 'icon-comment', );
$icon-contact-phone-name: 'icon-contact-phone';
$icon-contact-phone-x: 0px;
$icon-contact-phone-y: 200px;
$icon-contact-phone-offset-x: 0px;
$icon-contact-phone-offset-y: -200px;
$icon-contact-phone-width: 40px;
$icon-contact-phone-height: 40px;
$icon-contact-phone-total-width: 140px;
$icon-contact-phone-total-height: 985px;
$icon-contact-phone-image: '../img/sprite.png';
$icon-contact-phone: (0px, 200px, 0px, -200px, 40px, 40px, 140px, 985px, '../img/sprite.png', 'icon-contact-phone', );
$icon-deliver-name: 'icon-deliver';
$icon-deliver-x: 0px;
$icon-deliver-y: 60px;
$icon-deliver-offset-x: 0px;
$icon-deliver-offset-y: -60px;
$icon-deliver-width: 2px;
$icon-deliver-height: 30px;
$icon-deliver-total-width: 140px;
$icon-deliver-total-height: 985px;
$icon-deliver-image: '../img/sprite.png';
$icon-deliver: (0px, 60px, 0px, -60px, 2px, 30px, 140px, 985px, '../img/sprite.png', 'icon-deliver', );
$icon-mail-name: 'icon-mail';
$icon-mail-x: 0px;
$icon-mail-y: 90px;
$icon-mail-offset-x: 0px;
$icon-mail-offset-y: -90px;
$icon-mail-width: 40px;
$icon-mail-height: 30px;
$icon-mail-total-width: 140px;
$icon-mail-total-height: 985px;
$icon-mail-image: '../img/sprite.png';
$icon-mail: (0px, 90px, 0px, -90px, 40px, 30px, 140px, 985px, '../img/sprite.png', 'icon-mail', );
$icon-phone-name: 'icon-phone';
$icon-phone-x: 0px;
$icon-phone-y: 160px;
$icon-phone-offset-x: 0px;
$icon-phone-offset-y: -160px;
$icon-phone-width: 40px;
$icon-phone-height: 40px;
$icon-phone-total-width: 140px;
$icon-phone-total-height: 985px;
$icon-phone-image: '../img/sprite.png';
$icon-phone: (0px, 160px, 0px, -160px, 40px, 40px, 140px, 985px, '../img/sprite.png', 'icon-phone', );
$icon-scroll-name: 'icon-scroll';
$icon-scroll-x: 0px;
$icon-scroll-y: 240px;
$icon-scroll-offset-x: 0px;
$icon-scroll-offset-y: -240px;
$icon-scroll-width: 20px;
$icon-scroll-height: 45px;
$icon-scroll-total-width: 140px;
$icon-scroll-total-height: 985px;
$icon-scroll-image: '../img/sprite.png';
$icon-scroll: (0px, 240px, 0px, -240px, 20px, 45px, 140px, 985px, '../img/sprite.png', 'icon-scroll', );
$icon-sidebar-nav-name: 'icon-sidebar-nav';
$icon-sidebar-nav-x: 0px;
$icon-sidebar-nav-y: 12px;
$icon-sidebar-nav-offset-x: 0px;
$icon-sidebar-nav-offset-y: -12px;
$icon-sidebar-nav-width: 7px;
$icon-sidebar-nav-height: 12px;
$icon-sidebar-nav-total-width: 140px;
$icon-sidebar-nav-total-height: 985px;
$icon-sidebar-nav-image: '../img/sprite.png';
$icon-sidebar-nav: (0px, 12px, 0px, -12px, 7px, 12px, 140px, 985px, '../img/sprite.png', 'icon-sidebar-nav', );
$icon-view-name: 'icon-view';
$icon-view-x: 0px;
$icon-view-y: 24px;
$icon-view-offset-x: 0px;
$icon-view-offset-y: -24px;
$icon-view-width: 19px;
$icon-view-height: 13px;
$icon-view-total-width: 140px;
$icon-view-total-height: 985px;
$icon-view-image: '../img/sprite.png';
$icon-view: (0px, 24px, 0px, -24px, 19px, 13px, 140px, 985px, '../img/sprite.png', 'icon-view', );
$spritesheet-width: 140px;
$spritesheet-height: 985px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($icon-address, $icon-benefit-1, $icon-benefit-2, $icon-benefit-3, $icon-benefit-4, $icon-benefit-5, $icon-benefit-arrow, $icon-comment, $icon-contact-phone, $icon-deliver, $icon-mail, $icon-phone, $icon-scroll, $icon-sidebar-nav, $icon-view, );
$spritesheet: (140px, 985px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
