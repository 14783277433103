@import "../sprite";

.benefits {
	background: #2d2f38;
	padding: 70px 0;
	text-align: center;

	.default-title {
		color: #fff;
		font-size: 36px;
		font-weight: 300;
		text-transform: uppercase;
	}
}

.benefits__list {
	margin: 45px 0 0;
	padding: 0 55px;
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.benefits__list-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	flex-basis: 20%;

	&::before {
		content: '';
		display: inline-block;
		margin-bottom: 25px;
	}

	&:nth-child(1)::before {
		@include sprite($icon-benefit-1);
	}

	&:nth-child(2)::before {
		@include sprite($icon-benefit-2);
	}

	&:nth-child(3)::before {
		@include sprite($icon-benefit-3);
	}

	&:nth-child(4)::before {
		@include sprite($icon-benefit-4);
	}

	&:nth-child(5)::before {
		@include sprite($icon-benefit-5);
	}

	&:not(:first-child)::after {
		content: '';
		display: inline-block;
		@include sprite($icon-benefit-arrow);
		position: absolute;
		top: 38%;
		left: -6px;
		transform: translateY(-50%);
	}
}

.benefits__list-link {
	color: #fff;
	font-size: 15px;
	font-weight: 300;
	text-decoration: none;
}