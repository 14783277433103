.comments {
	margin-top: 30px;
}

.comments__form {
	width: 590px;
	margin: 40px 0 50px;

	.input {
		width: 100%;
		margin-bottom: 20px;
	}

	.textarea {
		width: 100%;
		margin-bottom: 30px;
		min-height: 190px;
	}
}

.comments__content {
	margin-bottom: 40px;
}

.comments__content-count {
	color: #3f4556;
	font-size: 18px;
	line-height: 30px;
	font-weight: 700;
}

.comments__content-item {
	border-bottom: 2px solid #f5f4f2;
	color: #3f4556;
	margin-bottom: 20px;
}

.comments__content-name {
	font-size: 15px;
	line-height: 30px;
	text-transform: uppercase;
	margin: 0;
}

.comments__content-date {
	opacity: 0.5;
	font-size: 15px;
	margin: 0;
}

.comments__content-text {
	margin: 20px 0;
	width: 700px;
}