@import "../sprite";

.articles__wrapper {
	.default-title {
		font-size: 36px;
		font-weight: 300;
		text-transform: uppercase;
	}
}

.articles__item {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 2px solid #f5f4f2;
	margin-bottom: 40px;
	padding-bottom: 25px;
}

.articles__item-img {
	width: 265px;

	img {
		width: 100%;
	}
}

.articles__item-content {
	width: 550px;
}

.articles__item-title {
	margin: 0;

	a {
		color: #da3200;
		font-size: 18px;
		line-height: 30px;
		font-weight: 600;
		text-transform: uppercase;
		text-decoration: none;
	}
}

.articles__item-info {
	color: #3F4556;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.articles__item-comments {
	display: flex;
	align-items: center;
	margin-right: 15px;

	&::before {
		content: '';
		display: inline-block;
		@include sprite($icon-comment);
		margin-right: 5px;
	}
}

.articles__item-views {
	display: flex;
	align-items: center;

	&::before {
		content: '';
		display: inline-block;
		@include sprite($icon-view);
		margin-right: 5px;
	}
}

.articles__item-text {
	color: #3f4556;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	margin: 0 0 5px;
}

.articles__item-link {
	color: #da3200;
	font-size: 14px;

	&:hover {
		text-decoration: none;
	}
}