.reviews {
	padding: 70px 40px 35px;
	text-align: center;
}

.reviews__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 25px;
}

.reviews__item {
	width: 360px;
	background: #f5f4f2;
	box-sizing: border-box;
	padding: 30px;
}

.reviews__img {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	margin-bottom: 30px;
}

.reviews__text {
	color: #2d2f38;
	font-size: 15px;
	line-height: 21px;
	margin: 0 0 30px;
}

.reviews__author {
	border-top: 1px solid #dfdedb;
	padding-top: 20px;
}

.reviews__author-name {
	color: #2d2f38;
	font-size: 15px;
	line-height: 21px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
}

.reviews__author-position {
	color: #6d7886;
	font-size: 13px;
	line-height: 21px;
	font-weight: 300;
	margin: 0;
}