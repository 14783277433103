.capital {
	padding: 35px 40px 70px;
}

.capital__row {
	display: flex;
	justify-content: space-between;
}

.capital-cell {
	width: 540px;
}

.capital__text {
	color: #747b87;
	font-size: 15px;
	line-height: 24px;
	font-weight: 400;
}