@import "../sprite";

.sidebar-nav {
	background-color: #f5f4f2;
	border-radius: 4px;
	padding: 15px 30px;
}

.sidebar-nav__list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.sidebar-nav__list-item {
	&:not(:first-child) {
		border-top: 1px solid #dfdedb;
	}

	&--active .sidebar-nav__list-link,
	&:hover .sidebar-nav__list-link {
		color: #9c0032;
	}
}

.sidebar-nav__list-link {
	display: block;
	font-size: 15px;
	font-weight: 300;
	color: #434954;
	text-decoration: none;
	padding: 13px 0 12px;
	transition: color .3s ease-out;
	position: relative;

	&::after {
		content: '';
		@include sprite($icon-sidebar-nav);
		display: inline-block;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		transition: transform .2s;
	}

	&.open::after {
		margin-top: -4px;
		transform: rotate(90deg);
	}
}

.sidebar-nav__dropdown {
	margin: 0;
	padding: 0;
	list-style-type: none;

	overflow: hidden;
	max-height: 0;
	transition: max-height .3s;

	&.show {
		border-top: 1px solid #dfdedb;
		max-height: 300px;
	}
}

.sidebar-nav__dropdown-item {
	padding: 0 15px;

	&:not(:first-child) {
		border-top: 1px dashed #dfdedc;
	}

	&--active .sidebar-nav__dropdown-link {
		color: #9c0032;
	}
}

.sidebar-nav__dropdown-link {
	color: #2f3035;
	font-size: 14px;
	padding: 10px 0 8px;
	font-weight: 300;
	text-decoration: none;
	display: inline-block;
}