.clients {
	background: url('../img/clients-bg.jpg');
	padding: 85px 0;
}

.clients__content {
	width: 430px;
	margin-left: 100px;
	text-align: center;
}

.clients__title {
	color: #3F4757;
	font-size: 36px;
	line-height: 30px;
	font-weight: 300;
	text-transform: uppercase;
	margin: 0 0 30px;
}

.clients__count {
	display: flex;
	justify-content: center;
}

.clients__count-item {
	width: 63px;
	height: 88px;
	background-color: #6d7886;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 1px;
	color: #f5f5f5;
	font-size: 57px;
	font-weight: 400;
	position: relative;

	&::before {
		content: '';
		display: block;
		height: 1px;
		background: #fff;
		box-shadow: 0 -1px 0 #384052;
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
	}

	i {
		font-style: normal;
		position: relative;
		z-index: 1;
	}
}

.clients__text {
	color: #6D7886;
	font-size: 13px;
	line-height: 18px;
	font-weight: 300;
	margin: 25px 0 40px;
}

.clients__link {
	color: #bb3118;
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	margin-top: 25px;

	&:hover {
		text-decoration: none;
	}
}