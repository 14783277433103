.sidebar-clients {
	width: 265px;
	background-color: #f5f4f2;
	border-radius: 3px;
	box-sizing: border-box;
	padding: 25px 22px;
	margin-top: 20px;
	text-align: center;

	.btn-default {
		font-size: 21px;
		margin-bottom: 15px;
	}
}

.sidebar-clients__title {
	color: #3f4757;
	font-size: 28px;
	line-height: 40px;
	font-weight: 300;
	text-transform: uppercase;
	margin: 0;
}

.sidebar-clients__count {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

.sidebar-clients__count-item {
	width: 52px;
	height: 72px;
	background-color: #6d7886;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 1px;
	color: #f5f5f5;
	font-size: 46px;
	font-weight: 400;
	position: relative;

	&::before {
		content: '';
		display: block;
		height: 1px;
		background: #fff;
		box-shadow: 0 -1px 0 #384052;
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
	}

	i {
		font-style: normal;
		position: relative;
		z-index: 1;
	}
}

.sidebar-clients__text {
	color: #6d7886;
	font-size: 13px;
	line-height: 18px;
	font-weight: 300;
	margin: 0 0 25px;
}

.sidebar-clients__link {
	color: #b9291a;
	font-size: 14px;
	font-weight: 400;

	&:hover {
		text-decoration: none;
	}
}