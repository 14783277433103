@import "../sprite";

.contacts {

}

.contacts__wrapper {
	.default-title {
		text-transform: uppercase;
	}
}

.contacts__list {
	margin: 20px 0 50px;
	padding: 0 75px 0 20px;
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.contacts__list-item {
	position: relative;
	padding-left: 60px;
	color: #3f4556;

	&::before {
		content: '';
		display: inline-block;
		position: absolute;
		left: 0;
		top: 8px;
	}

	&:nth-child(1)::before {
		@include sprite($icon-address);
	}

	&:nth-child(2)::before {
		@include sprite($icon-mail);
	}

	&:nth-child(3)::before {
		@include sprite($icon-contact-phone);
	}
}

.contacts__list-title {
	margin: 0 0 5px;
	font-size: 16px;
	font-weight: 700;
}

.contacts__list-text {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
}

.contacts__list-link {
	color: #3f4556;
	text-decoration: none;
}

.contacts__requisites {
	background-color: #f5f4f2;
	border-radius: 3px;
	padding: 30px 70px;
}

.contacts__requisites-content {
	padding: 16px 20px;
	border-left: 5px solid #dc3100;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #3f4556;
}

.contacts__requisites-left {
	margin-right: 95px;
}

.contacts__requisites-title {
	margin: 0;
	font-size: 18px;
	font-weight: 700;
}

.contacts__requisites-text {
	margin: 0;
}