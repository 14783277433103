.footer {
	background: #2d2f38;
	padding: 40px;
}

.footer__row {
	display: flex;
	justify-content: space-between;

	&:not(:first-child) {
		margin-top: 35px;
	}
}

.footer__nav {
	display: flex;
	justify-content: flex-start;
	width: 755px;
}

.footer__nav-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

	&:not(:first-child) {
		margin-left: 70px;
	}
}

.footer__nav-list-link {
	color: #fff;
	font-size: 14px;
	line-height: 30px;
	font-weight: 600;
	text-decoration: none;
}

.footer__contacts {
	text-align: right;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
}

.footer__contacts-phone {
	margin: 0 0 10px;
}

.footer__contacts-address {
	margin: 0;
}

.footer__copy,
.footer__developed {
	margin: 0;
	opacity: 0.5;
	color: #fff;
	font-size: 13px;
	font-weight: 600;

	a {
		opacity: 0.5;
		color: #fff;
		text-decoration: none;
	}
}