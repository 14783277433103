@import "../sprite";

.article {
	.default-title {
		font-size: 36px;
		font-weight: 300;
		text-transform: uppercase;
	}

	border-bottom: 2px solid #f5f4f2;
	padding-bottom: 25px;
}

.article__img {
	width: 100%;
}

.article__text {
	color: #3f4556;
	font-size: 14px;
	line-height: 24px;
}

.article__info {
	margin: -5px 0 10px;
	color: #3F4556;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.article__info-comments {
	display: flex;
	align-items: center;
	margin-right: 15px;

	&::before {
		content: '';
		display: inline-block;
		@include sprite($icon-comment);
		margin-right: 5px;
	}
}

.article__info-views {
	display: flex;
	align-items: center;

	&::before {
		content: '';
		display: inline-block;
		@include sprite($icon-view);
		margin-right: 5px;
	}
}