.page {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0 40px;

	&.index-capital {
		padding: 60px 40px 100px;
	}

	&.contacts {
		padding: 40px 40px 20px;
	}

	&.articles {
		padding: 40px;
	}
}

.page-left {
	width: 265px;
}

.page-right {
	width: 835px;
}