.main-about {
	background: url('../img/main-about-bg.png') 95% 40px no-repeat;
	padding-bottom: 1px;
}

.main-about__text {
	font-size: 18px;
	line-height: 30px;
	font-weight: 300;
	color: #343542;
	margin: 20px 0;
	width: 510px;

	span {
		color: #bb3118;
	}

	&--smaller {
		font-size: 15px;
		line-height: 24px;
	}

	&--bold {
		font-size: 15px;
		line-height: 24px;
		font-weight: 700;
	}
}